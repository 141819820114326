import React from 'react';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import Login from 'pages/Login';
import Home from 'pages/Home';
import PrivateRoute from 'routes/PrivateRoute';
import RouteConsts from 'routes/Routes.jsx';

import { useQuery } from '@apollo/react-hooks';
import DetectUrl from 'utils/DetectUrl';
import Client from 'gqlclient/Client';
import { useStore } from 'store/storeUtils';

import CrmLoader from 'components/generic/CrmLoader';

import StartingScreen from 'components/specific/StartingScreen';

import { autoLogin } from 'axios/REST_api';

import Cookies from 'js-cookie';
import psl from 'psl';

import { useFullScreen } from 'react-browser-hooks';

import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import LoadPardot from 'utils/LoadPardot';
import LinkedInTag from 'react-linkedin-insight';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import inizializeAnalytics from "utils/analytics.js"

import 'App.css';

function loadHTMLElement(filename, filetype) {
  let fileref;
  if (filetype === 'js') {
    fileref = document.createElement('script');
    fileref.setAttribute('type', 'text/javascript');
    fileref.setAttribute('src', filename);
  } else if (filetype === 'css') {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', 'stylesheet');
    fileref.setAttribute('type', 'text/css');
    fileref.setAttribute('href', filename);
  } else if (filetype.includes('icon')) {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', filetype);
    fileref.setAttribute('href', filename);
  } else if (filetype === 'manifest') {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', 'manifest');
    fileref.setAttribute('href', filename);
  }
  if (typeof fileref !== 'undefined') {
    document.getElementsByTagName('head')[0].appendChild(fileref);
  }
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs)

export const App = () => {
  const store = useStore();

  function isIoS() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform,
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  const [isLoading, setLoading] = React.useState(true);

  const prjInfo = useQuery(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()),
  );

  React.useEffect(() => {
    if (
      prjInfo &&
      !prjInfo.loading &&
      prjInfo.called &&
      prjInfo.data &&
      prjInfo.data.getProjectInfoByHost
    ) {
      store.setAssetsByObject(prjInfo.data.getProjectInfoByHost);
      loadHTMLElement(
        `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
          store.loginProjectName,
        )}/global/img/favicon.ico`,
        'icon',
      );
      loadHTMLElement(
        `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
          store.loginProjectName,
        )}/global/img/logo192.png`,
        'apple-touch-icon',
      );

      document.title = store.pageTitles.businessPlatform
        ? store.pageTitles.businessPlatform
        : `${store.loginProjectName} | Floor Planning 3D`;

      // initialize analytics
      if (store.floorPlanningConfig?.analytics) {
        if (store.floorPlanningConfig.analytics.facebookPixel?.id) {
          ReactPixel.init(
            store.floorPlanningConfig.analytics.facebookPixel.id,
            {},
            { autoConfig: true, debug: false },
          );
          ReactPixel.pageView();
        }
        if (store.floorPlanningConfig.analytics.googleAnalytics?.id) {
          ReactGA.initialize(store.floorPlanningConfig.analytics.googleAnalytics.id);
          ReactGA.pageview(window.location.pathname);
        }
        if (
          store.floorPlanningConfig.analytics.pardot?.piAId &&
          store.floorPlanningConfig.analytics.pardot?.piCId
        ) {
          LoadPardot(
            store.floorPlanningConfig.analytics.pardot.piAId,
            store.floorPlanningConfig.analytics.pardot.piCId,
          );
        }
        if (store.floorPlanningConfig.analytics.linkedIn?.partnerId) {
          LinkedInTag.init(store.floorPlanningConfig.analytics.linkedIn.partnerId);
        }
      }
    }
  }, [prjInfo]);

  React.useEffect(() => {
    const pathName = document.location.pathname;
    if (pathName !== '/login' && store.isDesk !== 1) {
      window.location.href = process.env.REACT_APP_HOME_CONFIGURATOR_URL;
      return;
    }

    autoLogin()
      .then((res) => {
        const { jwt } = res.data;
        console.log('🚀 ~ file: App.js ~ line 43 ~ autoLogin ~ jwt', jwt);

        let domain = 'localhost';
        if (window.location.hostname !== 'localhost') {
          const pslUrl = psl.parse(window.location.hostname);
          domain = pslUrl.domain;
        }
        const cookieOptions = {
          // expires: 1,
          domain: domain,
        };
        Cookies.set('jwt', jwt, cookieOptions);
        setLoading(false);
      })
      .catch((err) => {
        console.error('❌ ~ App.js ~ line 55 ~ autoLogin ~ err', err);
        setLoading(false);
      });
  }, []);

  const fs = useFullScreen();
  React.useEffect(() => {
    if (!fs.fullScreen) {
      const startingScreen = document.getElementById('starting-screen-container');
      if (startingScreen) {
        startingScreen.style.display = 'flex';
        const startingButton = document.getElementById('starting-screen-container-button');
        const new_startingButton = startingButton.cloneNode(true);
        startingButton.parentNode.replaceChild(new_startingButton, startingButton);
        new_startingButton.addEventListener('click', () => {
          fs.toggle();
          startingScreen.style.display = 'none';
        });
      }
    }
  }, [fs.fullScreen]);

  return (
    <>
      {/* {(!prjInfo.data || prjInfo.loading) && <CrmLoader z loading />} */}
      {isLoading ? (
        <CrmLoader z hasBackdrop transparency loading />
      ) : (
        <HelmetProvider context={{}}>
          {store.floorPlanningConfig?.analytics?.googleAds?.id && (
            <Helmet>
              {/* Global site tag (gtag.js) - Google Analytics  */}
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${store.floorPlanningConfig.analytics.googleAds.id}`}
              ></script>
              <script>
                {`
              window.dataLayer = window.dataLayer || []; 
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date()); 
              gtag('config', "${store.floorPlanningConfig.analytics.googleAds.id}");
              `}
              </script>
            </Helmet>
          )}
          {isIoS() ? (
            <Helmet>
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover'
              />
            </Helmet>
          ) : (
            <Helmet>
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1, shrink-to-fit=no'
              />
            </Helmet>
          )}
          <div className='rotate-phone'>
            <img
              src={require('assets/images/icons/screen-orientation.svg')}
              alt='screen-orientation'
            />
            <p>Ruota il tuo dispositivo per iniziare l’esperienza</p>
          </div>
          <Router>
            <Switch>
              <Route path='/:path?' component={LayoutRoot} />
            </Switch>
          </Router>
        </HelmetProvider>
      )}
    </>
  );
};

export const LayoutRoot = () => {
  var { path } = useParams();
  //TODO: add some validation here and inform user if tenant is invalid
  return (
    <>
      <StartingScreen />
      <Router basename='/'>
        <Switch>
          <Route exact path='/login' component={Login} />
          {/* TUTTE LE ROUTE, ORDINATE PER ORDER */}
          {RouteConsts.sort((a, b) => a.order - b.order).map((el) => (
            <PrivateRoute
              breadcrumb={el.breadcrumb}
              exact={el.exact}
              key={el.order}
              path={el.to}
              ToRender={el.component}
              title={el.title}
            />
          ))}

          {/* DEFAULT */}
          <PrivateRoute path={'/'} ToRender={Home} title={'Dashboard'} />
        </Switch>
      </Router>
    </>
  );
};
